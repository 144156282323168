import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, SecondaryCallout} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import nsclc1LmonoSafetyTable from "../../../assets/NSCLC-1L-mono-safety-small-V3.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-042: Most Common Adverse Reactions`,
    keywords: `keynote-042 adverse reactions`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions associated with the KEYNOTE-042 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-first-line-monotherapy/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-first-line-monotherapy/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE⁠-⁠042","description":"The most common adverse reactions resulting in discontinuation were pneumonitis (3.0%), death due to unknown cause (1.6%), and pneumonia (1.4%). The most frequent serious adverse reactions reported in at least 2% of patients were pneumonia (7%), pneumonitis (3.9%), pulmonary embolism (2.4%), and pleural effusion (2.2%). "}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const lungMonoSafetyAlt = "Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA® (pembrolizumab) in KEYNOTE-042"

//from /src/util/data/indications.js
const indicationId = 6;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: '1L Combo Therapy – Squamous', url: '/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy/' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/safety/adverse-reactions/nsclc-second-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/safety/adverse-reactions/nsclc-perioperative-regimen/' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/safety/adverse-reactions/nsclc-adjuvant-therapy/' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;042'} 
]

const nsclc1LmonoDataTableFootnotes = [
    {
        label: 'c.',
        text: 'Graded per NCI-CTCAE v4.03.'
    },
    {
        label: 'd.',
        text: 'Includes fatigue and asthenia.'
    },
    {
        label: 'e.',
        text: 'Includes rash, rash generalized, rash macular, rash maculopapular, rash papular, rash pruritic, and rash pustular.'
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                            <p>Safety was evaluated in 1,251 patients with previously untreated stage III NSCLC who were not candidates for surgical resection or definitive chemoradiation, or metastatic NSCLC. All patients had tumors expressing PD&#8288;-&#8288;L1.</p>
                            <p>Permanent discontinuation rate due to adverse reactions: 19% of 636 patients with KEYTRUDA.</p>
                            <p>The most common adverse reactions resulting in discontinuation were pneumonitis (3.0%), death due to unknown cause (1.6%), and pneumonia (1.4%). The most frequent serious adverse reactions reported in at least 2% of patients were pneumonia (7%), pneumonitis (3.9%), pulmonary embolism (2.4%), and pleural effusion (2.2%).</p>
                            </TextBlock>
                            <DataTable
                                title='Adverse Reactions Occurring in ≥10% of Patients'
                                footnotes={nsclc1LmonoDataTableFootnotes}
                                mobileImage={() => <img src={nsclc1LmonoSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={lungMonoSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/NSCLC-1L-mono-safety-large-V3.png" alt={lungMonoSafetyAlt}/>}
                            />
                        </PageSection>
                    </TemplateColumn>
                {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
